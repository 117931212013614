
import React from 'react';
import Head from 'next/head';
import {GlobalStyle} from '@hy-vee/web-core/lib/global';
import {GlobalNavigationProvider} from '@hy-vee/global-navigation';
import {ApolloProvider} from '@apollo/client';
import '@hy-vee/design-tokens/src/tokens.css';
import '@hy-vee/design-system/lib/design-system.css';
import '@hy-vee/global-navigation/lib/cjs/index.css';
import '@hy-vee/react-web-and-mobile-ui-components/whammy.css';
import {initialize as monitoringInitialize} from '@hy-vee/monitoring';

import '../../css/index.css';
import '../../css/reset.css';
import ModalProvider from '../components/modals/modal-provider';
import {graphqlClient} from '../graphql/graphql-client';
import FeatureToggleProvider from '../feature-toggle/provider';

const clientPaths = {
    apiPrefix: '/perks/api',
    loginPath: '/perks/login',
    logoutPath: '/perks/logout',
};

const MyApp = (props) => {
    const {Component, ...pageProps} = props;
    const client = graphqlClient();
    const {isAuthenticated} = pageProps?.pageProps?.reduxState || false;

    React.useEffect(() => {
        monitoringInitialize();
    }, []);

    return (
        <>
            <Head>
                <title>{'Hy-Vee PERKS'}</title>
            </Head>
            <GlobalStyle />
            <GlobalNavigationProvider clientPaths={clientPaths}>
                <ApolloProvider client={client}>
                    <FeatureToggleProvider isAuthenticated={isAuthenticated}>
                        <ModalProvider>
                            <Component {...pageProps} />
                        </ModalProvider>
                    </FeatureToggleProvider>
                </ApolloProvider>
            </GlobalNavigationProvider>
        </>
    );
};

export default MyApp;
